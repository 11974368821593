//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { saveInLocalStorage, retrieveFromLocalStorage } from "@/helpers/local-storage";

export default {
    data() {
        return {
            step: 0
        };
    },

    computed: {
        app() {
            return this.$store.getters["application/getApplication"];
        },
        onboarding_faq_questions() {
            return this.$store.getters["faq/getOnboardingQuestions"];
        }
    },

    methods: {
        onSubmit() {
            saveInLocalStorage(`app_${this.app._id}_onboarding_passed`, "passed");
            this.$router.push({
                name: "debtor-data"
            });
        }
    },

    mounted() {
        const s = retrieveFromLocalStorage(`app_${this.app._id}_onboarding_passed`);
        if (s == "passed") {
            this.$router.push({
                name: "debtor-data"
            });
        }
    },

    metaInfo: {
        title: "Co warto wiedzieć przed rozpoczęciem tworzenia wniosku"
    }
};
